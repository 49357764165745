import { keyframes } from "tss-react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  loader: {
    position: "relative",
    width: 216,
    minHeight: 96,
    display: "flex",
    justifyContent: "space-between",
    marginTop: 200,
    marginLeft: "auto",
    marginRight: "auto",

    "&:after,&:before": {
      content: '""',
      display: "inline-block",
      width: 96,
      height: 96,
      backgroundColor: "#FFF",
      backgroundImage:
        "radial-gradient(circle 28px, #0d161b 100%, transparent 0)",
      backgroundRepeat: "no-repeat",
      borderRadius: "50%",
      animation: `${eyeMove} 10s infinite`,
      border: "1px solid",
    },
  },
}));

const eyeMove = keyframes`
    0%, 10% { background-position: 0px 0px }
    13%, 40% { background-position: -15px 0px }
    43%, 70% { background-position: 15px 0px }
    73%, 90%  { background-position: 0px 15px }
    93%, 100% { background-position: 0px 0px }
    `;

const blink = keyframes`
    0%, 10%, 12%, 20%, 22%, 40%, 42%, 60%, 62%, 70%, 72%, 90%, 92%, 98%, 100%{
      height: 48px
    }
    11%, 21%, 41%, 61%, 71%, 91%, 99% { height: 18px }`;

export default useStyles;
