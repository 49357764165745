import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  loader: {
    width: "22px!important",
    height: "22px!important",
  },
}));

export default useStyles;
