import { Box, Button, Grid, Typography } from "@mui/material";
import { useHomePageStore } from "../../store/homePage";
import Section from "../layout/Section";
import Title from "../reusable/Title";

const AboutMe = () => {
  const { data } = useHomePageStore();

  return (
    <Section id={"o-mnie"}>
      <Grid container={true} alignItems={"center"}>
        <Grid item={true} xs={12} md={5}>
          <img width={"100%"} src={data?.aboutMe.image} alt={""} />
        </Grid>
        <Grid item={true} xs={12} md={7}>
          <Box maxWidth={450} ml={{ md: 14, lg: 28 }}>
            <Grid container={true} spacing={6}>
              <Grid item={true}>
                <Title title={"O mnie"} />
              </Grid>
              <Grid item={true}>
                <Typography variant={"body1"}>
                  {data?.aboutMe.description}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Button
                  href={"#portfolio"}
                  size={"large"}
                  variant={"contained"}
                  color={"secondary"}
                >
                  Zobacz moje prace
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
};

export default AboutMe;
