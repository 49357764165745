import { Navigate } from "react-router-dom";
import { routes } from "../../../routes/routes";
import { useAuthStore } from "../../../store/auth";
import { IProtectedRouteProps } from "./ProtectedRoute.types";

const ProtectedRoute = ({ children }: IProtectedRouteProps) => {
  const { jwt } = useAuthStore();

  if (!jwt) {
    return <Navigate to={routes.login} replace />;
  }

  return children;
};

export default ProtectedRoute;
