import { Button, Grid, Typography } from "@mui/material";
import { useHomePageStore } from "../../store/homePage";
import Section from "../layout/Section";
import useStyles from "./Billboard.styles";

const Billboard = () => {
  const { classes } = useStyles();
  const { data } = useHomePageStore();

  return (
    <Section id={"strona-glowna"}>
      <Grid
        className={classes.wrapper}
        flexDirection={{ xs: "column-reverse", md: "row" }}
        container={true}
        alignItems={"center"}
      >
        <Grid className={classes.descContainer} item={true} xs={12} md={6}>
          <Grid container={true} spacing={8}>
            <Grid item={true}>
              <Grid container={true} spacing={2}>
                <Grid item={true}>
                  <Typography variant={"h1"}>
                    {data?.firstSection.title}
                  </Typography>
                </Grid>

                <Grid item={true}>
                  <Typography variant={"h3"}>
                    {data?.firstSection.description}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item={true}>
              <Button
                href={"#o-mnie"}
                size={"large"}
                variant={"contained"}
                color={"secondary"}
              >
                O mnie
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <img src={data?.firstSection.image} width={"100%"} alt={""} />
        </Grid>
      </Grid>
    </Section>
  );
};

export default Billboard;
