import { Grid } from "@mui/material";
import { useHomePageStore } from "../../store/homePage";
import Section from "../layout/Section";
import Title from "../reusable/Title";
import Price from "./Price";

const Prices = () => {
  const { data } = useHomePageStore();

  return (
    <Section id="cennik">
      <Grid container={true} spacing={8}>
        <Grid item={true} xs={12}>
          <Title title={"Cennik"} />
        </Grid>
        <Grid item={true} xs={12}>
          <Grid container={true} spacing={8} alignItems="stretch">
            {data?.prices.map((item) => (
              <Price price={item} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Section>
  );
};

export default Prices;
