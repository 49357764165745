import { Grid, Paper, Typography } from "@mui/material";
import Divider from "../../reusable/Divider";
import { IPriceProps } from "./Price.types";
import ReactMarkdown from "react-markdown";

const Price = ({ price }: IPriceProps) => {
  return (
    <Grid item={true} xs={12} md={6}>
      <Paper sx={{ height: "100%" }}>
        <Grid p={{ md: 5, xs: 3 }} container={true} spacing={1}>
          <Grid item={true} xs={12}>
            <Typography variant={"h3"}>{price.title}</Typography>
          </Grid>

          <Grid item={true} xs={12}>
            <Divider fullWidth />
          </Grid>

          <Grid item={true} xs={12}>
            <Typography>
              <ReactMarkdown>{price.description}</ReactMarkdown>
            </Typography>
          </Grid>

          <Grid item={true} xs={12} alignSelf={"flex-end"}>
            <Typography variant={"h4"}>Cena: {price.value} zł</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Price;
