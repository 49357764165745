import { AlertColor } from "@mui/material";
import { create } from "zustand";

interface IToast {
  message: string;
  severity: AlertColor;
}

interface ToastStore {
  toast: IToast | null;
  setToast: (toast: IToast | null) => void;
}

export const useToastStore = create<ToastStore>()((set) => ({
  toast: null,
  setToast: (toast) => set(() => ({ toast })),
}));
