import { Alert, Snackbar } from "@mui/material";
import { useToastStore } from "../../../store/toast";

const Toast = () => {
  const { toast, setToast } = useToastStore();

  const handleClose = () => {
    setToast(null);
  };
  return (
    <Snackbar
      open={!!toast}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        onClose={handleClose}
        severity={toast?.severity}
        sx={{ width: "100%" }}
        variant={"standard"}
      >
        {toast?.message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
