import { object, string } from "yup";
import { ILoginBody } from "../../services/$login-api/types";

export const initialValues: ILoginBody = {
  identifier: "",
  password: "",
};

const schema = {
  identifier: string()
    .email("Błędny format e-mail.")
    .required("Pole e-mail jest wymagane."),
  password: string().required("Pole hasło jest wymagane."),
};

export const LoginSchema = object().shape(schema);
