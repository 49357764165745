import { create } from "zustand";
import { IUserDataResponse } from "../../services/$login-api/types";

interface AuthStore {
  jwt?: string;
  userData: IUserDataResponse | null;
  setJWT: (jwt?: string) => void;
  setUserData: (userData: IUserDataResponse) => void;
}

export const useAuthStore = create<AuthStore>()((set) => ({
  userData: null,
  jwt: undefined,
  setJWT: (jwt) => set(() => ({ jwt })),
  setUserData: (userData) => set(() => ({ userData })),
}));
