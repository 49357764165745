import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    // position: "absolute",
    // top: 0,
    // width: "100%",
    // left: "50%",
    // transform: "translate(-50%, 0)",
  },
  descContainer: {
    position: "relative",
  },
}));

export default useStyles;
