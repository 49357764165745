import apiClient from "../../utils/apiClient";
import { ILoginBody, ILoginResponse, IUserDataResponse } from "./types";

class LoginApi {
  public login(body: ILoginBody): Promise<ILoginResponse> {
    return new Promise<ILoginResponse>((resolve, reject) => {
      apiClient.defaults.headers.common["Authorization"] = null;
      apiClient
        .post("auth/local", body)
        .then((response) => resolve(response.data))
        .catch(reject);
    });
  }

  public getUserData(): Promise<IUserDataResponse> {
    return new Promise<IUserDataResponse>((resolve, reject) => {
      apiClient
        .get("users/me", {
          params: {
            populate: "*",
          },
        })
        .then((response) => resolve(response.data))
        .catch(reject);
    });
  }
}

export default new LoginApi();
