import AboutMe from "../../components/AboutMe";
import Billboard from "../../components/Billboard";
import Contact from "../../components/Contact";
import Page from "../../components/layout/Page";
import Portfolio from "../../components/Portfolio";
import Prices from "../../components/Prices";
import Loader from "../../components/reusable/Loader";
import useHomePage from "./useHomePage";
import useFilters from "./useFilters";

const Home = () => {
  const { isLoading } = useHomePage();
  const { isLoading: isFiltersLoading } = useFilters();

  if (isLoading || isFiltersLoading) {
    return (
      <Page>
        <Loader />
      </Page>
    );
  }

  return (
    <Page>
      <Billboard />
      <AboutMe />
      <Portfolio />
      <Prices />
      <Contact />
    </Page>
  );
};

export default Home;
