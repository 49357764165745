import { IEmailSendBody } from "./../../services/$email-api/types";
import { useMutation } from "react-query";
import EmailApi from "../../services/$email-api";

const useEmail = () => {
  const { data, error, isError, isLoading, isSuccess, mutate } = useMutation(
    async (body: IEmailSendBody) => await EmailApi.sendEmail(body)
  );

  return {
    isLoading,
    isSuccess,
    isError,
    data,
    error,
    mutate,
  };
};

export default useEmail;
