import { useCollectionStore } from "../../store/collection";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { urlToPromise } from "../../utils/urlToPromise";

const zip = new JSZip();

const useDownloadCollection = () => {
  const { collection } = useCollectionStore();

  const downloadCollection = () => {
    if (collection) {
      const img = zip.folder(collection.name);
      if (img) {
        collection?.cloudinary_images_images.forEach((image) => {
          img.file(`${image.name}.jpg`, urlToPromise(image.url), {
            binary: true,
          });
        });

        zip.generateAsync({ type: "blob" }).then(function (content) {
          saveAs(content, `${collection.name}.zip`);
        });
      }
    }
  };

  return {
    downloadCollection,
  };
};

export default useDownloadCollection;
