import PrivatePage from "../../components/layout/PrivatePage";
import Gallery from "../../components/reusable/Gallery";
import Loader from "../../components/reusable/Loader";
import { useCollectionStore } from "../../store/collection";
import useCollection from "./useCollection";
import useUserData from "./useUserData";

const MyImages = () => {
  const { data, isLoading: isUserDataLoading } = useUserData();
  const { isLoading: isCollectionLoading } = useCollection({
    id: data?.cloudinary_images_collection.id,
  });

  const { collection } = useCollectionStore();

  if (isUserDataLoading || isCollectionLoading || !collection) {
    return <Loader />;
  }

  return (
    <PrivatePage>
      <Gallery images={collection.cloudinary_images_images} />
    </PrivatePage>
  );
};

export default MyImages;
