import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import useStyles from "./Gallery.styles";
import { IGalleryProps } from "./Gallery.types";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative, Navigation, Thumbs, FreeMode, Keyboard } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/effect-creative";

const Gallery = ({ images }: IGalleryProps) => {
  const { classes } = useStyles();
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const theme = useTheme();
  const match = useMediaQuery(() => theme.breakpoints.down("sm"));

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12}>
        <Swiper
          spaceBetween={10}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[Keyboard, EffectCreative, Navigation, Thumbs]}
          effect={"creative"}
          grabCursor={true}
          keyboard={{
            enabled: true,
          }}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: ["-120%", 0, -500],
            },
            next: {
              shadow: true,
              translate: ["120%", 0, -500],
            },
          }}
        >
          {images.map((image) => (
            <SwiperSlide className={classes.slider}>
              <div
                style={{ backgroundImage: `url(${image.url})` }}
                className={classes.blur}
              />
              <div
                style={{ backgroundImage: `url(${image.url})` }}
                className={classes.mainImage}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
      <Grid item={true} xs={12}>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={match ? 3 : 6}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className={classes.swipper}
        >
          {images.map((image) => (
            <SwiperSlide>
              <div
                className={classes.thumb}
                style={{ backgroundImage: `url(${image.url})` }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
    </Grid>
  );
};

export default Gallery;
