import { Button, CircularProgress, Grid, TextField } from "@mui/material";
import { IFormLayoutProps } from "./FormLayout.types";
import DoneIcon from "@mui/icons-material/Done";
import { useEffect } from "react";
import useStyles from "./FormLayout.styles";

const FormLayout = ({
  handleBlur,
  handleChange,
  handleSubmit,
  errors,
  touched,
  resetForm,
  isSuccess,
  isLoading,
  values,
}: IFormLayoutProps) => {
  const { classes } = useStyles();

  useEffect(() => {
    if (isSuccess) {
      resetForm();
    }
  }, [isSuccess]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12} md={6}>
          <TextField
            variant={"standard"}
            fullWidth={true}
            label={(touched.name && errors.name) || "Twoje imię"}
            color={"secondary"}
            name={"name"}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!(touched.name && errors.name)}
            value={values.name}
          />
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <TextField
            variant={"standard"}
            fullWidth={true}
            label={(touched.email && errors.email) || "Twój adres e-mail"}
            color={"secondary"}
            name={"email"}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!(touched.email && errors.email)}
            value={values.email}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            variant={"standard"}
            fullWidth={true}
            label={(touched.message && errors.message) || "Twoja wiadomość"}
            color={"secondary"}
            name={"message"}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!(touched.message && errors.message)}
            value={values.message}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <Button
            size={"large"}
            variant={"contained"}
            color={"secondary"}
            type={"submit"}
            disabled={isLoading || isSuccess}
            endIcon={
              isLoading ? (
                <CircularProgress className={classes.loader} />
              ) : isSuccess ? (
                <DoneIcon />
              ) : null
            }
          >
            {isSuccess ? "Wysłano" : "Wyślij"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormLayout;
