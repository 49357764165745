import JSZipUtils from "jszip-utils";

export const urlToPromise = (url: string) => {
  return new Promise<any>((resolve, reject) => {
    JSZipUtils.getBinaryContent(url, (err: any, data: any) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
};
