import { Button, Grid } from "@mui/material";
import { useEffect } from "react";
import { IFilterProps } from "./Filters.types";

const Filters = ({
  selectedFilters,
  setSelectedFilters,
  filters,
}: IFilterProps) => {
  const filtersKeys = Object.keys(filters);

  const handleActiveFilter = (filter: string) => {
    setSelectedFilters((oldState) => ({
      ...filters,
      [filter]: { ...oldState[filter], isSelected: true },
    }));
  };

  useEffect(() => {
    handleActiveFilter(filtersKeys[0]);
  }, []);

  return (
    <Grid container={true} spacing={4}>
      {filtersKeys.map((key) => (
        <Grid item={true} key={key}>
          <Button
            variant={"text"}
            color={"secondary"}
            style={
              selectedFilters[key].isSelected ? { color: "#0E1112" } : undefined
            }
            onClick={() => handleActiveFilter(key)}
          >
            {selectedFilters[key].title}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default Filters;
