import { ICollectionResponse } from "./../../services/$collection-api/types";
import { create } from "zustand";

interface CollectionStore {
  collection: ICollectionResponse | null;
  setCollection: (collection: ICollectionResponse) => void;
}

export const useCollectionStore = create<CollectionStore>()((set) => ({
  collection: null,
  setCollection: (collection) => set(() => ({ collection })),
}));
