import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  container: {
    backgroundColor: "#EEF7F9",
    paddingTop: 150,
    paddingBottom: 150,
    marginTop: 136,
  },
  imageContainer: {
    position: "relative",
  },
  image: {
    position: "absolute",
    bottom: -150,
    right: 0,
  },
}));

export default useStyles;
