import { IPrice } from "./../../components/Prices/Price/Price.types";
import { INormalizedHomePage, IHomePageResponse, IPortfolio } from "./types";
import apiClient from "../../utils/apiClient";

class HomePageApi {
  public getHomePage() {
    return new Promise<INormalizedHomePage>((resolve, reject) => {
      apiClient
        .get<IHomePageResponse>("home-page", {
          params: { populate: "deep,3" },
        })
        .then((response) => {
          resolve(this.normalizeHomePage(response.data));
        })
        .catch((error) => reject(error));
    });
  }

  private normalizeHomePage(data: IHomePageResponse): INormalizedHomePage {
    const {
      data: {
        attributes: { logo, firstSection, aboutMe },
      },
    } = data;
    return {
      logo,
      firstSection: {
        description: firstSection.description,
        title: firstSection.title,
        image: firstSection.image.data.attributes.url,
      },
      aboutMe: {
        description: aboutMe.description,
        image: aboutMe.image.data.attributes.url,
      },
      portfolios: this.normalizePortfolio(data),
      prices: this.normalizePrices(data),
    };
  }

  private normalizePortfolio(data: IHomePageResponse): IPortfolio[] {
    const normalized: IPortfolio[] = [];
    const {
      data: {
        attributes: { portfolios },
      },
    } = data;

    if (portfolios) {
      portfolios.data.forEach((portfolio) => {
        portfolio.attributes.images.data?.forEach((item) => {
          normalized.push({
            img: item.attributes.url,
            category: portfolio.attributes.image_category.data.attributes.slug,
          });
        });
      });
    }

    return normalized;
  }

  private normalizePrices(data: IHomePageResponse): IPrice[] {
    const normalized: IPrice[] = [];
    const {
      data: {
        attributes: { prices },
      },
    } = data;

    if (prices) {
      prices.data.forEach((price) => {
        normalized.push({
          id: price.id,
          title: price.attributes.name,
          value: price.attributes.price,
          description: price.attributes.description,
        });
      });
    }

    return normalized;
  }
}

export default new HomePageApi();
