import { Box, Button, Grid, Hidden } from "@mui/material";
import { Formik } from "formik";
import { Link as RouterLink } from "react-router-dom";
import { config } from "../../config/config";
import { routes } from "../../routes/routes";
import { ILoginBody } from "../../services/$login-api/types";
import logo from "./../../images/logo.png";
import FormLayout from "./FormLayout";
import { initialValues, LoginSchema } from "./Login.helpers";
import useStyles from "./Login.styles";
import useLogin from "./useLogin";

const Login = () => {
  const { classes } = useStyles();
  const { mutate } = useLogin();

  const handleSubmit = (values: ILoginBody) => {
    mutate(values);
  };

  return (
    <Grid container={true}>
      <Grid item={true} xs={12} md={6}>
        <Box sx={{ p: 6 }}>
          <Box sx={{ pb: 4 }}>
            <Grid container={true} alignItems={"center"}>
              <img src={logo} height={config.logoHeight} />
            </Grid>
          </Box>
          <Box sx={{ p: { md: 10 } }}>
            <Grid container={true} spacing={1}>
              <Grid item={true} xs={12}>
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={LoginSchema}
                  component={FormLayout}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Button
                  fullWidth={true}
                  to={routes.index}
                  variant={"text"}
                  component={RouterLink}
                >
                  Powrót
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Hidden mdDown={true}>
        <Grid item={true} md={6}>
          <div className={classes.image} />
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default Login;
