import { IUserDataResponse } from "./../../services/$login-api/types";
import { useAuthStore } from "./../../store/auth/index";
import { useQuery } from "react-query";
import LoginApi from "../../services/$login-api";

const useUserData = () => {
  const { setUserData } = useAuthStore();
  const { isLoading, isSuccess, isError, data, error, refetch } = useQuery<
    IUserDataResponse
  >("query-user-data", async () => await LoginApi.getUserData(), {
    onSuccess: (response) => {
      setUserData(response);
    },
  });

  return {
    isLoading,
    isSuccess,
    isError,
    data,
    error,
    refetch,
  };
};

export default useUserData;
