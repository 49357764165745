import apiClient from "../../utils/apiClient";
import { IEmailSendBody } from "./types";
class EmailApi {
  public sendEmail(body: IEmailSendBody): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      apiClient
        .post("email", body)
        .then((response) => resolve(response.data))
        .catch(reject);
    });
  }
}

export default new EmailApi();
