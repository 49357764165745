import { Grid, Typography } from "@mui/material";
import Title from "../../reusable/Title";
import Socials from "../Menu/Socials";
import Section from "../Section";
import Divider from "./../../reusable/Divider";

const Footer = () => {
  return (
    <Section>
      <Grid container={true} spacing={8}>
        <Grid item={true} xs={12}>
          <Grid container={true}>
            <Grid item={true} xs={12} md={4}>
              <Grid container={true} spacing={4}>
                <Grid item={true} xs={12}>
                  <Title isSmaller={true} title={"Skontaktuj się"} />
                </Grid>
                <Grid item={true} xs={12}>
                  <Typography>dorotarusniak@gmail.com</Typography>
                  <Typography>+48 796 468 902</Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid item={true} xs={12} md={4}>
              <Grid container={true} spacing={4}>
                <Grid item={true} xs={12}>
                  <Title isSmaller={true} title={"Where’s My Office?"} />
                </Grid>
                <Grid item={true} xs={12}>
                  <Typography>2489 Locust Court, Los Angeles</Typography>
                  <Typography>3927 Red Maple Drive, Los Angeles</Typography>
                </Grid>
              </Grid>
            </Grid> */}

            <Grid item={true} xs={12} md={4}>
              <Grid container={true} spacing={4}>
                <Grid item={true} xs={12}>
                  <Title isSmaller={true} title={"Moje social media"} />
                </Grid>
                <Grid item={true} xs={12}>
                  <Socials />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item={true} xs={12}>
          <Divider fullWidth={true} />
        </Grid>
        <Grid item={true} xs={12} pb={12}>
          <Typography align={"center"}>© 2023 All Rights Reserved</Typography>
        </Grid>
      </Grid>
    </Section>
  );
};

export default Footer;
