import { Box } from "@mui/material";
import { ISectionProps } from "./Section.types";

const Section = ({ id, children, withoutMargin }: ISectionProps) => {
  return (
    <Box
      id={id}
      sx={{
        maxWidth: 1300,
        margin: "0 auto",
        position: "relative",
        marginTop: withoutMargin ? undefined : 17,
        paddingLeft: 5,
        paddingRight: 5,
      }}
    >
      {children}
    </Box>
  );
};

export default Section;
