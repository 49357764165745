import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import PinterestIcon from "@mui/icons-material/Pinterest";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

export const socials = {
  fb: {
    url: "https://www.facebook.com/dorotarusniakphotography",
    icon: <FacebookOutlinedIcon />,
  },
  // twitter: {
  //   url: "https://twitter.com",
  //   icon: <TwitterIcon />,
  // },
  // pinterest: {
  //   url: "https://pl.pinterest.com/",
  //   icon: <PinterestIcon />,
  // },
  instagram: {
    url: "https://instagram.com/dorotarusniakfotografia?igshid=YmMyMTA2M2Y=",
    icon: <InstagramIcon />,
  },
  // yt: {
  //   url: "https://www.youtube.com/",
  //   icon: <YouTubeIcon />,
  // },
};
