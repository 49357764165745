import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingLeft: 65,
    paddingRight: 65,
    paddingBottom: 34,
    paddingTop: 34,

    [theme.breakpoints.down("md")]: {
      paddingLeft: 35,
      paddingRight: 35,
    },
  },
}));

export default useStyles;
