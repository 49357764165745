import { IFiltersResponse, INormalizedFilters } from "./types";
import apiClient from "../../utils/apiClient";

class FiltersApi {
  public getFilters() {
    return new Promise<INormalizedFilters>((resolve, reject) => {
      apiClient
        .get<IFiltersResponse>("image-categories", {
          params: { populate: "deep,3" },
        })
        .then((response) => {
          resolve(this.normalizeFilters(response.data));
        })
        .catch((error) => reject(error));
    });
  }

  private normalizeFilters(data: IFiltersResponse): INormalizedFilters {
    const { data: filters } = data;
    return filters.map((filter) => ({
      name: filter.attributes.name,
      slug: filter.attributes.slug,
    }));
  }
}

export default new FiltersApi();
