import apiClient from "../../utils/apiClient";
import { ICollectionResponse } from "./types";

class CollectionApi {
  public getUserCollection(id?: number): Promise<ICollectionResponse> {
    return new Promise<ICollectionResponse>((resolve, reject) => {
      apiClient
        .get(`cloudinary-images/collection/${id}`)
        .then((response) => resolve(response.data))
        .catch(reject);
    });
  }
}

export default new CollectionApi();
