import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingLeft: 11,
    paddingRight: 11,
    paddingBottom: 14,
    paddingTop: 14,
  },
}));

export default useStyles;
