import { object, string } from "yup";
import { IFormLayoutValues } from "./FormLayout/FormLayout.types";

export const initialValues: IFormLayoutValues = {
  name: "",
  email: "",
  message: "",
};

const schema = {
  name: string().required("Imię jest wymagane"),
  email: string()
    .email("Nieprawidłowy format e-mail")
    .required("E-mail jest wymagany"),
  message: string().required("Wiadomość jest wymagana"),
};

export const ContactSchema = object().shape(schema);
