import { createBrowserRouter } from "react-router-dom";
import ProtectedRoute from "../components/functional/ProtectedRoute";
import Home from "../pages/Home/Home.component";
import Login from "../pages/Login/Login.component";
import MyImages from "../pages/MyImages/MyImages.component";
import { routes } from "./routes";

export const router = createBrowserRouter([
  {
    path: routes.index,
    element: <Home />,
  },
  {
    path: routes.login,
    element: <Login />,
  },
  {
    path: routes.myImages,
    element: (
      <ProtectedRoute>
        <MyImages />
      </ProtectedRoute>
    ),
  },
]);
