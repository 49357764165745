import { ICollectionResponse } from "./../../services/$collection-api/types";
import { useQuery } from "react-query";
import CollectionApi from "../../services/$collection-api";
import { useCollectionStore } from "../../store/collection";

interface ICollectionParams {
  id?: number;
}

const useUserData = ({ id }: ICollectionParams) => {
  const { setCollection } = useCollectionStore();
  const { isLoading, isSuccess, isError, data, error, refetch } = useQuery<
    ICollectionResponse
  >("query-collection", async () => await CollectionApi.getUserCollection(id), {
    onSuccess: (response) => {
      setCollection(response);
    },
    enabled: !!id,
  });

  return {
    isLoading,
    isSuccess,
    isError,
    data,
    error,
    refetch,
  };
};

export default useUserData;
