import {
  AppBar,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Slide,
  Toolbar,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { IMobileProps } from "./Mobile.types";
import useStyles from "./Mobile.styles";
import Socials from "../Socials";
import { links } from "../Links/links";
import { routes } from "../../../../routes/routes";
import { Link as RouterLink } from "react-router-dom";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Mobile = ({ open, close }: IMobileProps) => {
  const { classes } = useStyles();

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <DialogContent>
        <AppBar className={classes.container} position={"relative"}>
          <Toolbar disableGutters>
            <Grid container={true} justifyContent={"flex-end"}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={close}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Toolbar>
        </AppBar>
        {links.map((link) => (
          <Link title={link.id} href={link.id} underline={"none"}>
            <MenuItem onClick={close} divider={true}>
              {link.name}
            </MenuItem>
          </Link>
        ))}
        <Link
          to={routes.login}
          variant={"body1"}
          underline={"none"}
          component={RouterLink}
        >
          <MenuItem onClick={close} divider={true}>
            Zaloguj
          </MenuItem>
        </Link>
        <Grid justifyContent={"center"} container={true}>
          <Socials />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Mobile;
