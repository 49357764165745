import { Grid, IconButton } from "@mui/material";
import { socials } from "./socials";
import { ISocialKeys } from "./Socials.types";

const Socials = () => {
  const socialsKeys = Object.keys(socials) as ISocialKeys[];

  return (
    <Grid item={true}>
      <Grid container={true}>
        {socialsKeys.map((value) => (
          <Grid key={value} item={true}>
            <IconButton href={socials[value].url}>
              {socials[value].icon}
            </IconButton>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Socials;
