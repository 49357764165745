import {
  Box,
  Grid,
  ImageList,
  ImageListItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useHomePageStore } from "../../store/homePage";
import Section from "../layout/Section";
import Title from "../reusable/Title";
import Filters from "./Filters";
import { ISelectedFilters } from "./Filters/Filters.types";

const Portfolio = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const { data, filters: filtersData } = useHomePageStore();

  const filters = useMemo(() => {
    const normalizedFilters: ISelectedFilters = {};

    filtersData.forEach((filter, index) => {
      normalizedFilters[filter.slug] = {
        title: filter.name,
        isSelected: false,
      };
    });

    return normalizedFilters;
  }, [filtersData]);

  const [selectedFilters, setSelectedFilters] = useState(filters);

  const filteredItems = useMemo(() => {
    const keys = Object.keys(selectedFilters);
    const selectedFilterKey = keys.find(
      (key) => selectedFilters[key].isSelected
    );

    if (selectedFilters) {
      return data?.portfolios.filter(
        (item) => item.category === selectedFilterKey
      );
    }
    return data?.portfolios;
  }, [selectedFilters]);

  return (
    <Section id={"portfolio"}>
      <Grid container={true} spacing={6}>
        <Grid item={true}>
          <Box ml={{ md: 19 }}>
            <Grid container={true} spacing={6}>
              <Grid item={true} xs={12}>
                <Title title={"Portfolio"} />
              </Grid>
              <Grid item={true} xs={12}>
                <Filters
                  setSelectedFilters={setSelectedFilters}
                  selectedFilters={selectedFilters}
                  filters={filters}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item={true} xs={12}>
          {filteredItems && (
            <ImageList variant="masonry" cols={matches ? 1 : 3} gap={34}>
              {filteredItems.map((item) => (
                <ImageListItem key={item.img}>
                  <img
                    src={`${item.img}?w=248&fit=crop&auto=format`}
                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    loading="lazy"
                    alt={""}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          )}
        </Grid>
      </Grid>
    </Section>
  );
};

export default Portfolio;
