import { useQuery } from "react-query";
import FiltersApi from "../../services/$filters-api";
import { useHomePageStore } from "../../store/homePage";
import { INormalizedFilters } from "../../services/$filters-api/types";

const useFilters = () => {
  const { setFilters } = useHomePageStore();
  const { isLoading, isSuccess, isError, data, error, refetch } = useQuery<
    INormalizedFilters
  >("query-filters", async () => await FiltersApi.getFilters(), {
    onSuccess: (response) => {
      setFilters(response);
    },
  });

  return {
    isLoading,
    isSuccess,
    isError,
    data,
    error,
    refetch,
  };
};

export default useFilters;
