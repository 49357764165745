import { Button, Grid, TextField, Typography } from "@mui/material";
import { IFormLayoutProps } from "./FormLayout.types";

const FormLayout = ({
  touched,
  errors,
  handleBlur,
  handleChange,
  values,
  handleSubmit,
}: IFormLayoutProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={12}>
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={12}>
              <Typography variant={"h3"} textAlign={"center"}>
                Witamy ponownie!
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography
                variant={"body2"}
                color={"textSecondary"}
                textAlign={"center"}
              >
                Zaloguj się swoimi danymi, aby uzyskać dostęp do swoich zdjęć.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            variant={"standard"}
            label={(touched.identifier && errors.identifier) || "E-mail"}
            helperText={"Wpisz adres email"}
            color={"secondary"}
            name={"identifier"}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!(touched.identifier && errors.identifier)}
            value={values.identifier}
            fullWidth={true}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            variant={"standard"}
            label={(touched.password && errors.password) || "Hasło"}
            helperText={"Wpisz swoje hasło"}
            color={"secondary"}
            name={"password"}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!(touched.password && errors.password)}
            value={values.password}
            fullWidth={true}
            type={"password"}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <Button
            variant={"contained"}
            color={"secondary"}
            fullWidth={true}
            type={"submit"}
            size={"medium"}
          >
            Zaloguj
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormLayout;
