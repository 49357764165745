import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./config/theme";
import { QueryClient, QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import Toast from "./components/layout/Toast";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
        <Toast />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
