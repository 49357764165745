import { INormalizedHomePage } from "./../../services/$home-page-api/types";
import { create } from "zustand";
import { INormalizedFilters } from "../../services/$filters-api/types";

interface HomePageStore {
  data: INormalizedHomePage | null;
  filters: INormalizedFilters;
  set: (data: INormalizedHomePage) => void;
  setFilters: (data: INormalizedFilters) => void;
}

export const useHomePageStore = create<HomePageStore>()((set) => ({
  data: null,
  filters: [],
  set: (data) => set(() => ({ data })),
  setFilters: (filters) => set(() => ({ filters })),
}));
