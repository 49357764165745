import { Grid, Link } from "@mui/material";
import { links } from "./links";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "../../../../routes/routes";

const Links = () => {
  return (
    <Grid item={true}>
      <Grid container={true} spacing={4}>
        {links.map((link) => (
          <Grid key={link.id} item={true}>
            <Link
              title={link.id}
              href={link.id}
              variant={"body1"}
              underline={"none"}
            >
              {link.name}
            </Link>
          </Grid>
        ))}
        <Grid item={true}>
          <Link
            to={routes.login}
            variant={"body1"}
            underline={"none"}
            component={RouterLink}
          >
            Zaloguj
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Links;
