import LogoutIcon from "@mui/icons-material/Logout";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import {
  AppBar,
  Grid,
  IconButton,
  Toolbar,
  Button,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import { config } from "../../../config/config";
import useStyles from "./PrivatePage.styles";
import { IPrivatePageProps } from "./PrivatePage.types";

import logo from "./../../../images/logo.png";

import useLogin from "../../../pages/Login/useLogin";
import useDownloadCollection from "../../../pages/MyImages/useDownloadCollection";

const PrivatePage = ({ children }: IPrivatePageProps) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const match = useMediaQuery(() => theme.breakpoints.down("sm"));

  const { downloadCollection } = useDownloadCollection();

  const { logout } = useLogin();

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12}>
        <AppBar className={classes.appBar} position="static">
          <Toolbar>
            <Grid
              container={true}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid item={true}>
                <img src={logo} height={config.logoHeight} alt={""} />
              </Grid>
              <Grid item={true}>
                <Grid container={true} spacing={1} alignItems={"center"}>
                  <Grid item={true}>
                    {match ? (
                      <IconButton
                        size="large"
                        color="inherit"
                        onClick={downloadCollection}
                      >
                        <CloudDownloadOutlinedIcon />
                      </IconButton>
                    ) : (
                      <Button
                        startIcon={<CloudDownloadOutlinedIcon />}
                        variant={"outlined"}
                        color={"secondary"}
                        size={"small"}
                        onClick={downloadCollection}
                      >
                        Pobierz zdjęcia
                      </Button>
                    )}
                  </Grid>
                  <Grid item={true}>
                    <IconButton size="large" color="inherit" onClick={logout}>
                      <LogoutIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Grid>

      <Grid item={true} xs={12}>
        <Box sx={{ padding: 2, paddingBottom: 4 }}>{children}</Box>
      </Grid>
    </Grid>
  );
};

export default PrivatePage;
