import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  blur: {
    height: "calc(100vh - 120px)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    pointerEvents: "none",
    backgroundRepeat: "no-repeat",
    filter: "blur(14px) brightness(1.15)",
  },

  mainImage: {
    height: "calc(100vh - 120px)",
    backgroundSize: "contain",
    backgroundPosition: "center",
    pointerEvents: "none",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },

  thumb: {
    height: 100,
    backgroundSize: "cover",
    backgroundPosition: "center",
    pointerEvents: "none",
    backgroundRepeat: "no-repeat",
  },

  slider: {
    position: "relative",
  },

  swipper: {
    "& .swiper-slide": {
      opacity: 0.4,
      cursor: "pointer",
    },
    "& .swiper-slide-thumb-active": {
      opacity: 1,
    },
  },
}));

export default useStyles;
