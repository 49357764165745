import { INormalizedHomePage } from "../../services/$home-page-api/types";
import { useQuery } from "react-query";
import HomePageApi from "../../services/$home-page-api";
import { useHomePageStore } from "../../store/homePage";

const useHomePage = () => {
  const { set } = useHomePageStore();
  const { isLoading, isSuccess, isError, data, error, refetch } = useQuery<
    INormalizedHomePage
  >("query-home-page", async () => await HomePageApi.getHomePage(), {
    onSuccess: (response) => {
      set(response);
    },
  });

  return {
    isLoading,
    isSuccess,
    isError,
    data,
    error,
    refetch,
  };
};

export default useHomePage;
