import { Divider, Grid, Typography } from "@mui/material";
import { ITitleProps } from "./Title.types";

const Title = ({ title, isSmaller }: ITitleProps) => {
  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12}>
        <Typography variant={isSmaller ? "h4" : "h2"}>{title}</Typography>
      </Grid>
      <Grid item={true} xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default Title;
