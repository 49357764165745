import { useToastStore } from "./../../store/toast/index";
import { useAuthStore } from "./../../store/auth/index";
import { ILoginBody } from "./../../services/$login-api/types";
import { useMutation } from "react-query";
import LoginApi from "../../services/$login-api";
import { routes } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import apiClient from "../../utils/apiClient";

const useLogin = () => {
  const { setJWT } = useAuthStore();
  const { setToast } = useToastStore();
  const navigate = useNavigate();

  const { data, error, isError, isLoading, isSuccess, mutate } = useMutation({
    mutationFn: async (body: ILoginBody) => await LoginApi.login(body),
    onSuccess: (data) => {
      setJWT(data.jwt);
      navigate(routes.myImages);
      apiClient.defaults.headers.common["Authorization"] = "Bearer " + data.jwt;
    },
    onError: () => {
      setToast({
        message: "Podany email lub hasło jest nieprawidłowe!",
        severity: "error",
      });
    },
  });

  const logout = () => {
    setJWT(undefined);
    navigate(routes.login);
    apiClient.defaults.headers.common["Authorization"] = null;
  };

  return {
    isLoading,
    isSuccess,
    isError,
    data,
    error,
    mutate,
    logout,
  };
};

export default useLogin;
