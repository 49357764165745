import {
  AppBar,
  Grid,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import useStyles from "./Menu.styles";
import CameraSharpIcon from "@mui/icons-material/CameraSharp";
import Links from "./Links";
import Socials from "./Socials";
import HideOnScroll from "../../functional/HideOnScroll";
import MenuIcon from "@mui/icons-material/Menu";
import Mobile from "./Mobile";
import { useState } from "react";
import { useHomePageStore } from "../../../store/homePage";
import logo from "./../../../images/logo.png";
import { config } from "../../../config/config";

const Menu = () => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const { data } = useHomePageStore();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <HideOnScroll>
        <AppBar className={classes.container}>
          <Toolbar disableGutters>
            <Grid
              container={true}
              alignItems={"flex-end"}
              justifyContent={{ lg: "space-between", md: "center" }}
            >
              <Grid item={true}>
                <Grid container={true} alignItems={"center"}>
                  <img src={logo} height={config.logoHeight} alt={""} />
                </Grid>
              </Grid>
              <Hidden mdDown={true}>
                <Grid item={true}>
                  <Grid
                    container={true}
                    spacing={{ lg: 8, md: 2 }}
                    alignItems={"flex-end"}
                    justifyContent={{ xs: "center" }}
                  >
                    <Links />
                    <Socials />
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden mdUp={true}>
                <Grid item={true} xs={true}>
                  <Grid container={true} justifyContent={"flex-end"}>
                    <IconButton
                      onClick={handleOpen}
                      size="large"
                      edge="start"
                      color="inherit"
                    >
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Mobile open={open} close={handleClose} />
    </>
  );
};

export default Menu;
