import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

// const rootElement = document.getElementById("root");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

// if (rootElement?.hasChildNodes()) {
//   ReactDOM.hydrate(<App />, rootElement);
// } else {
//   ReactDOM.render(<App />, rootElement);
// }
