import cn from "classnames";
import useStyles from "./Divider.styles";
import { IDividerProps } from "./Divider.types";
import { Divider as MuiDivider } from "@mui/material";

const Divider = ({ fullWidth }: IDividerProps) => {
  const { classes } = useStyles();

  return <MuiDivider className={cn(fullWidth && classes.fullWidth)} />;
};

export default Divider;
