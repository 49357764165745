import { createTheme, responsiveFontSizes } from "@mui/material";
import MulishRegular from "./../fonts/Mulish-Regular.ttf";
import MulishBold from "./../fonts/Mulish-Bold.ttf";
import PlayFairRegular from "./../fonts/PlayfairDisplay-Regular.ttf";
import PlayFairBold from "./../fonts/PlayfairDisplay-Bold.ttf";

export const theme = responsiveFontSizes(
  createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: `
            html{
              scroll-behavior: smooth
            }
            @font-face {
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 400;
                src: local('Mulish'), url(${MulishRegular}) format('truetype');
            }
            @font-face {
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 700;
                src: local('Mulish'), url(${MulishBold}) format('truetype');
            }
            @font-face {
                font-family: 'Playfair Display';
                font-style: normal;
                font-weight: 400;
                src: local('Playfair Display'), url(${PlayFairRegular}) format('truetype');
            }
            @font-face {
                font-family: 'Playfair Display';
                font-style: normal;
                font-weight: 700;
                src: local('Playfair Display'), url(${PlayFairBold}) format('truetype');
            }
          `,
      },

      MuiSvgIcon: {
        styleOverrides: {
          fontSizeLarge: {
            fontSize: "3rem",
          },
        },
      },

      MuiLink: {
        styleOverrides: {
          root: {
            lineHeight: "30px",
            color: "#8F9294",
          },
        },
      },

      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 0,
          },
          textSizeMedium: {
            fontSize: 16,
            color: "#8F9294",
          },
          sizeMedium: {
            padding: "18px 50px",
            letterSpacing: "1px",
            fontSize: 16,
            lineHeight: "20px",
          },
          sizeLarge: {
            padding: "18px 50px",
            letterSpacing: "1px",
            fontSize: 16,
            lineHeight: "31px",
          },
        },
      },

      MuiDivider: {
        styleOverrides: {
          root: {
            width: 76,
            backgroundColor: "#0E1112",
            height: 1,
          },
        },
      },
    },
    palette: {
      primary: {
        main: "#fff",
      },
      secondary: {
        main: "#0E1112",
      },
    },
    typography: {
      fontFamily: "Mulish, Arial",
      h1: {
        fontFamily: "Playfair Display",
        fontWeight: 700,
        fontSize: 108,
        lineHeight: 1.2,
        letterSpacing: "-5px",
      },
      h2: {
        fontFamily: "Playfair Display",
        fontWeight: 700,
        fontSize: 56,
        lineHeight: 1.43,
      },
      h3: {
        fontFamily: "Playfair Display",
        fontWeight: 400,
        fontSize: 36,
        lineHeight: 1.3,
        letterSpacing: "-2px",
      },
      h4: {
        fontFamily: "Playfair Display",
        fontWeight: 400,
        fontSize: 32,
        lineHeight: 1.4,
        letterSpacing: "-2px",
      },
      body1: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: 2.125,
        letterSpacing: "1.5px",
      },
      body2: {
        fontWeight: 400,
        fontSize: 16,
      },
    },
  })
);

theme.typography.h1 = {
  fontFamily: "Playfair Display",
  fontWeight: 700,
  fontSize: 108,
  lineHeight: 1.2,
  letterSpacing: "-5px",
  [theme.breakpoints.down("md")]: {
    fontSize: 48,
  },
};
