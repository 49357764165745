import { Grid } from "@mui/material";
import Footer from "../Footer";
import Menu from "../Menu";
import useStyles from "./Page.styles";
import { IPageProps } from "./Page.types";
import { Helmet } from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";
import { Person } from "schema-dts";

const Page = ({ children }: IPageProps) => {
  const { classes } = useStyles();

  return (
    <Grid
      container={true}
      className={classes.container}
      flexDirection={"column"}
    >
      <Helmet
        script={[
          helmetJsonLdProp<Person>({
            "@context": "https://schema.org",
            "@type": "Person",
            name: "Dorota Ruśniak",
            hasOccupation: {
              "@type": "Occupation",
              name: "Fotograf",
            },
          }),
        ]}
      >
        <title>Dorota Ruśniak - Fotografia, Sesje Nowy Sącz</title>
        <meta
          name="description"
          content="Dorota Ruśniak fotografia, sesje ślubne, sesje ciążowe, chrzty, sesje indywidualne"
        />
      </Helmet>
      <Grid item={true}>
        <Menu />
      </Grid>
      <Grid item={true}>{children}</Grid>
      <Grid item={true}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default Page;
