import { makeStyles } from "tss-react/mui";
import login from "./../../images/login.jpeg";

const useStyles = makeStyles()((theme) => ({
  image: {
    backgroundImage: `url(${login})`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

export default useStyles;
