import { Grid, Typography, Hidden } from "@mui/material";
import Section from "../layout/Section";
import Title from "../reusable/Title";
import useStyles from "./Contact.styles";
import contact from "./../../images/contact.png";
import FormLayout from "./FormLayout";
import { Formik } from "formik";
import { ContactSchema, initialValues } from "./Contact.helpers";
import useEmail from "../../pages/Home/useEmail";
import { IFormLayoutValues } from "./FormLayout/FormLayout.types";

const Contact = () => {
  const { classes } = useStyles();
  const { mutate, isLoading, isSuccess } = useEmail();

  const handleSubmit = (values: IFormLayoutValues) => {
    mutate(values);
  };

  return (
    <div className={classes.container} id={"kontakt"}>
      <Section withoutMargin={true}>
        <Grid container={true}>
          <Grid item={true} xs={12} md={6}>
            <Grid container={true} spacing={4}>
              <Grid item={true} xs={12}>
                <Title title={"Napisz do mnie"} />
              </Grid>

              <Grid item={true} xs={12}>
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={ContactSchema}
                  children={(props) => (
                    <FormLayout
                      isLoading={isLoading}
                      isSuccess={isSuccess}
                      {...props}
                    />
                  )}
                  enableReinitialize={true}
                />
              </Grid>
            </Grid>
          </Grid>

          <Hidden mdDown={true}>
            <Grid className={classes.imageContainer} item={true} xs={12} md={6}>
              <img
                className={classes.image}
                src={contact}
                width={"100%"}
                alt={""}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Section>
    </div>
  );
};

export default Contact;
